.sort-dialog-container {
  width: 180px;
  opacity: 1;
  position: absolute;
  left: 425px;
  padding-bottom: 5px;
  top: 65px;
  z-index: 5;
  border-radius: 5px;
  transition: all 0.1s ease;
  overflow: hidden;
  animation: popup 0.1s ease-in-out 0s 1;
}
.sort-by-category {
  margin-top: 4px;
}
.sort-by-category-list,
.sort-by-order-list {
  font-size: 15px;
  font-weight: 500;
  opacity: 1;
  line-height: 30px;
  width: calc(100% - 30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
}
.sort-dialog-seperator {
  width: 100%;
  height: 0px;
  margin: 6px 0px;
}
